import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

export const myFirebase = initializeApp({
    apiKey: process.env.GATSBY_FS_API_KEY,
    databaseURL: process.env.GATSBY_FS_DB_URL,
    authDomain: process.env.GATSBY_FS_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FS_PROJECT_ID,
    storageBucket: process.env.GATSBY_FS_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FS_MESSAGE_SENDER_ID,
    appId: process.env.GATSBY_FS_APP_ID,
})
export const db = getFirestore()
export const storage = getStorage()

// Connect to the Firebase Emulator instead if it's exists in .env
if (process.env.FIREBASE_STORAGE_EMULATOR_HOST) {
    const storageHost = process.env.FIREBASE_STORAGE_EMULATOR_HOST.split(':')
    connectStorageEmulator(storage, storageHost[0], storageHost[1])
}