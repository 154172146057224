import renderHTML from 'react-render-html'

export function getMessage(key, text='') {
    const messages = {
        emptyField: `Cannot be empty`,
        error: `There was an error`,
        formEmailInvalid: `Invalid email address`,
        formEmailVerify: `Please verify your email address.`,
        formPasswordInvalid: `Password must contain at least 6 characters`,
        formPasswordWeak: `The password is too weak`,
        loginFail: `Your email or password was incorrect`,
        loginFailIssue: `Login has failed`,
        loginDefault: `Need help? Email <a href="mailto:support@barrowsglobal.com">support@barrowsglobal.com</a>`
    }
    return renderHTML(messages[key])
}